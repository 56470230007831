
























































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class StatisticsCustomersOrdersInactivity extends Vue {
  filteringPanel = [0]
  filterLoading = false
  exportToExcelLoading = false

  lastOrderOptions = [
    { id: '1', number: '7', type: 'd', name: '7 days' },
    { id: '2', number: '14', type: 'd', name: '14 days' },
    { id: '3', number: '21', type: 'd', name: '21 days' },
    { id: '4', number: '1', type: 'm', name: '1 month' },
    { id: '5', number: '3', type: 'm', name: '3 months' },
    { id: '6', number: '6', type: 'm', name: '6 months' },
    { id: '7', number: '12', type: 'm', name: '12 months' },
  ]

  headers = []
  search = ''

  webshops = []
  priceLists = []
  customerGroups = []
  allCustomerSubGroups = []
  customerGroupSubGroups = []
  customersData = []

  filteringWebshops = null
  filteringPriceLists = null
  filteringCustomerGroup = null
  filteringCustomerSubGroup = null
  filteringLastOrder = { id: '1', number: '7', type: 'd', name: '7 days' }

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  appendSitePrefix = appendSitePrefix

  get requestData() {
    return {
      last_order_number:
        this.filteringLastOrder !== null && this.filteringLastOrder !== undefined
          ? this.filteringLastOrder.number
          : null,
      last_order_type:
        this.filteringLastOrder !== null && this.filteringLastOrder !== undefined
          ? this.filteringLastOrder.type
          : null,
      webshops: this.filteringWebshops,
      price_lists: this.filteringPriceLists,
      customer_group:
        this.filteringCustomerGroup !== null && this.filteringCustomerGroup !== undefined
          ? this.filteringCustomerGroup.id
          : null,
      customer_sub_group:
        this.filteringCustomerSubGroup !== null && this.filteringCustomerSubGroup !== undefined
          ? this.filteringCustomerSubGroup.id
          : null,
    }
  }

  get excelFileName() {
    const date = moment().format('YYYY-MM-DD')
    let result = 'customers_orders_inactivity'

    if (this.filteringCustomerGroup !== null) {
      result += '_' + this.filteringCustomerGroup.name.toLowerCase()
    }
    if (this.filteringCustomerSubGroup !== null) {
      result += '_' + this.filteringCustomerSubGroup.name.toLowerCase()
    }
    if (this.filteringLastOrder !== null) {
      result += '_' + this.filteringLastOrder.name.toLowerCase()
    }

    return result + '-' + date + '.xlsx'
  }

  created() {
    this.$axios.get('/v3/statistics/customers/orders_inactivity/get_initial_data').then((response) => {
      this.headers = response.data.data.headers
      this.webshops = response.data.data.webshops
      this.customerGroups = response.data.data.customerGroups
      this.allCustomerSubGroups = response.data.data.customerSubGroups
      this.priceLists = response.data.data.priceLists
    })
  }

  getCustomersData() {
    const self = this
    self.filterLoading = true

    this.$axios
      .post('/v3/statistics/customers/orders_inactivity/get_customers_data', this.requestData)
      .then((response) => {
        this.customersData = response.data.data.customersData
      })
      .finally(function () {
        self.filterLoading = false
      })
  }

  exportToExcel() {
    const self = this
    self.exportToExcelLoading = true

    this.$axios
      .post('/v3/statistics/customers/orders_inactivity/get_customers_data/export_to_excel', this.requestData)
      .then(function (response) {
        const blob = b64toBlob(response.data.data.binary, 'application/vnd.ms-excel')
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', self.excelFileName)
          document.body.appendChild(link)
          link.click()
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(blob, self.excelFileName)
        }
      })
      .finally(function () {
        self.exportToExcelLoading = false
      })
  }

  @Watch('filteringCustomerGroup')
  populateSubGroups() {
    if (this.filteringCustomerGroup != null) {
      this.customerGroupSubGroups = this.allCustomerSubGroups[this.filteringCustomerGroup.id]
    }
  }
}
